.Lets-content {
    font-weight: 300;
    line-height: 3.5rem;
}

@media (max-width: 736px) {
    .Lets-content {
        line-height: 2rem;
    }
}

@media (min-width: 1300px) {
    .Lets-content {
        line-height: 4.75rem;
        letter-spacing: 7px;
    }
}

@media (min-width: 950px) and (max-width: 1100px) {

    .Lets-content {
        font-size: 36px;
    }

    .Empower-content {
        font-size: 22px;
    }

    .spread-fabc {
        font-size: 100px;
        letter-spacing: 2rem;
    }
}

.border-icon-wave-1 {
    width: 65px;
    height: 65px;
    border: 2px solid #4F93FF;
    border-radius: 100%;
    padding: 5px;
    animation: icon-wave 2s infinite ease-in-out;
    position: absolute;
}

.border-icon-wave-2 {
    width: 80px;
    height: 80px;
    border: 2px solid #4f92ffb0;
    border-radius: 100%;
    padding: 5px;
    animation: icon-wave 2.5s infinite ease-in-out;
    position: absolute;
}

.border-icon-wave-3 {
    width: 95px;
    height: 95px;
    border: 2px solid #4f92ff4d;
    border-radius: 100%;
    padding: 5px;
    animation: icon-wave 3s infinite ease-in-out;
    position: absolute;
}

@keyframes icon-wave {
    0% {
        opacity: 0.2;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.2;
    }
}


.white-shadow-box {
    filter: drop-shadow(0 0 5px white);
}

.tools-shadow {
    filter: drop-shadow(0 0 5px #008CFF);
}

.next-button-shadow {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.small-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-image: linear-gradient(to right, #ffffff88, #FFFFFF33);
    position: absolute;
    right: -50px;
    top: 0;
    animation: small-circle-bounce 3s ease-in-out infinite;
}

.circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-image: linear-gradient(to right, #ffffff88, #FFFFFF33);
    position: absolute;
    right: -100px;
    top: 10%;
    animation: circle-bounce 4s ease-in-out infinite;
    animation-delay: 2s;

}

.small-square {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    background-image: linear-gradient(to right, #ffffff88, #FFFFFF33);
    position: absolute;
    right: -60px;
    top: 30%;
    animation: small-square-bounce 3.5s ease-in-out infinite;
    animation-delay: 3s;

}

.medium-square {
    width: 75px;
    height: 75px;
    border-radius: 5px;
    background-image: linear-gradient(to right, #ffffff88, #FFFFFF33);
    position: absolute;
    right: -75px;
    bottom: 5%;
    animation: medium-square-bounce 4s ease-in-out infinite;
    animation-delay: 4s;

}

.square {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    background-image: linear-gradient(to right, #ffffff88, #FFFFFF33);
    position: absolute;
    right: -100px;
    top: 50%;
    animation: square-bounce 6s ease-in-out infinite;
    animation-delay: 5s;
}


.square-2 {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    background-image: linear-gradient(to right, #ffffff88, #FFFFFF33);
    position: absolute;
    right: -100px;
    bottom: 0;
    animation: square-bounce-2 6s ease-in-out infinite;
    animation-delay: 6s;
}


@keyframes small-circle-bounce {
    0% {
        right: -50px;
        top: 0;
    }

    50% {
        right: 50%;
        top: 90%;
    }

    100% {
        right: 100%;
        top: 0;
    }
}

@keyframes circle-bounce {
    0% {
        right: -100px;
        top: 0;
    }

    25% {
        right: 20%;
        top: 85%;
    }

    50% {
        right: 40%;
        top: 0%;
    }

    75% {
        right: 60%;
        top: 85%;
    }

    100% {
        right: 100%;
        top: 20%;
    }
}

@keyframes small-square-bounce {
    0% {
        right: -60px;
        top: 30%;
        transform: rotate(0deg);
    }

    50% {
        right: 40%;
        top: 90%;
        transform: rotate(-180deg);
    }

    100% {
        right: 100%;
        top: 10%;
        transform: rotate(-360deg);
    }
}

@keyframes medium-square-bounce {
    0% {
        right: -75px;
        top: 60%;
        transform: rotate(0deg);
    }

    33% {
        right: 40%;
        top: 30%;
        transform: rotate(-180deg);
    }

    66% {
        right: 65%;
        top: 90%;
        transform: rotate(-360deg);
    }

    100% {
        right: 100%;
        top: 20%;
        transform: rotate(-580deg);
    }
}

@keyframes square-bounce {
    0% {
        right: -100px;
        top: 50%;
        transform: rotate(0deg);
    }

    25% {
        right: 30%;
        top: 85%;
        transform: rotate(-180deg);

    }

    50% {
        right: 60%;
        top: 0%;
        transform: rotate(-360deg);

    }

    75% {
        right: 80%;
        top: 85%;
        transform: rotate(-540deg);

    }

    100% {
        right: 100%;
        top: 40%;
        transform: rotate(-720deg);

    }
}

@keyframes square-bounce-2 {
    0% {
        right: -100px;
        bottom: 0;
        transform: rotate(0deg);
    }

    25% {
        right: 30%;
        bottom: 85%;
        transform: rotate(-180deg);

    }

    50% {
        right: 75%;
        bottom: 0%;
        transform: rotate(-360deg);

    }

    75% {
        right: 90%;
        bottom: 85%;
        transform: rotate(-540deg);

    }

    100% {
        right: 100%;
        bottom: 20%;
        transform: rotate(-720deg);

    }
}

@keyframes waveOpacity {
    0% {
        opacity: 0.2;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.2;
    }
}

.border-wave {
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    animation: waveOpacity 3s infinite ease-in-out;
    padding: 5px;
}

.border-wave-2 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    animation: waveOpacity 4s infinite ease-in-out;
    padding: 5px;
}