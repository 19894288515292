.tailor-range {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    background: #d3d3d3;
    outline: none;
    border-radius: 10px;
}

.tailor-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    background: white;
    cursor: pointer;
    transform: rotate(45deg);
    border: 3px solid #0E378B;
    box-shadow: 0px 2px 5px 1px #195EEB;
}

.tailor-range::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #04AA6D;
    cursor: pointer;
}